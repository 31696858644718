.btnCollectionReport {
    /* background-color: var(--body-main-dark-color); */
    padding: 10px 15px 10px 15px;
    border: 1px solid var(--body-main-dark-color);
    /* color: var(--body-main-dark-color); */
    border-radius: 5px;
    cursor: pointer;
    align-items: center;
    align-content: center;
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, .2), 0 6px 10px 0 rgba(0, 0, 0, .14), 0 1px 18px 0 rgba(0, 0, 0, .12);
    background-color: var(--body-main-dark-color);
    color: var(--button-color-light);
}

.btnCollectionReport span {
    padding-right: 5px;
    align-items: center;
}

.collection-dialog {
    background-color: #f5f5f5;
    border-radius: 8px;
    /* padding: 20px; */
    /* width: 650px; */
    /* padding: 10px; */
    padding-left: 20px;
    padding-right: 20px;
    /* width: 900px; */
    width: 850px !important;
    font-family: 'Arial', sans-serif;
    color: #333;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    height: 610px !important;
    /* height: 850px !important; */
}

.header {
    /* background-color: #4a148c; */
    /* color: white; */
    padding: 10px;
    border-radius: 8px 8px 0 0;
    text-align: left;
    margin-left: 0;
    width: 100%;
}

.employee-info{
    margin-bottom: 8px; 
}
.cheque-r {
    display: flex;
    justify-content: space-between;
    background-color: #eee;
    padding: 8px;
    border-radius: 4px;
    padding-top: 12px;
    padding-bottom: 12px;
}

.cheque-r div {
    flex: 1;
    padding: 4px;
}

.cheque-r p {
    margin: 0; 
}

.neft-row {
    display: flex;
    justify-content: space-between;
    background-color: #eee;
    padding: 8px;
    border-radius: 4px;
    padding-top: 12px;
    padding-bottom: 12px;
}

.neft-row div {
    /* flex: 1; */
    padding: 5px;
}

.neft-row p {
    margin: 0; 
}

.cheque-section, .cash-section, .invoice-section {
    margin-top: 20px;
}

.cheque-section h4, .cash-section h4, .invoice-section h4 {
    color: #4a148c;
    /* color: var(--body-main-dark-color); */
    margin-bottom: 10px;
}

.cheque-details, .cash-grid {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.cheque-img {
    width: 85px !important;
    height: 50px;
    border: 2px solid #ddd;
    border-radius: 4px;
    object-fit: cover;
}

.cash-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    background-color: #fff;
    padding: 10px;
    border-radius: 8px;
}

.cash-row {
    background-color: #e3f2fd;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
}

.cash-row:hover {
    background-color: #bbdefb;
}

.cheque-row, .cash-row {
    display: flex;
    justify-content: space-between;
    background-color: #eee;
    padding: 8px;
    border-radius: 4px;
}

.invoice-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
}

.invoice-table th, .invoice-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
}

.invoice-table th {
    /* background-color: #4a148c; */
    background-color: var(--body-main-dark-color);
    /* background-color:var(--body-main-light-color); */
    /* background-color:  #9ac5e9; */
    color: white;
}

.invoice-table td {
    background-color: #fafafa;
}

.invoice-section  .MuiPaper-root .MuiTableContainer-root{
    /* width: 1080px !important; */
    /* height: 300px !important; */
    /* max-height: 450px !important; */
    height: 200px;
    /* max-height: 200px !important; */
    /* max-width: 1080px; */
    overflow-y: auto !important;
}

.invoice-section .MuiPaper-root{
    height: 200px !important;
    width: inherit;
}

/* .MuiPaper-root-MuiDialog-paper{
    width: 1050px !important;

} 
.MuiDialog-root .MuiBackdrop-root-MuiDialog-backdrop .MuiDialog-container .MuiPaper-root-MuiDialog-paper{
    width: 950px !important;
  
} */

