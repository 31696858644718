:root {
    --body-main-dark-color: #243C5C;
    --body-main-light-color: #B3C9E6;
    /* --body-main-light-color: #9dbadfc7; */
    /* --body-main-light-color: #6182aac7; */
    /* --body-main-light-color: #849dbd; */
    /* --sidebar-bg-light-color: #f1f1f1; */
    --sidebar-bg-light-color: #e2e5e7;
    --sidebar-ul-hover-color: #ddd;
    --white: #fff;
    --sidebar-font-color: #333;
    --sidebar-bg-light: rgb(37, 37, 66);
    --sidebar-bg-dark: #555;
    --main-bg-light: #fff;
    --main-bg-dark: #444;
    --button-bg-light: #007bff;
    --button-hover-bg-light: #0056b3;
    --button-bg-dark: #bbb;
    --button-hover-bg-dark: #999;
    --button-color-light: #fff;
    --button-color-dark: #333;
    --table-border-light: #ddd;
    --table-border-dark: red;
    --text-color-dark: #fff;
}

.login_main {
    height: 100vh;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login_card {
    /* height: 38%; */
    width: 40%;
    background-color: #0056b3;
    display: flex;
    flex-direction: row;
    box-shadow: 0px 0px 11px 1px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 0px 0px 11px 1px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 11px 1px rgba(0, 0, 0, 0.75);
    border-radius: 5px;
    overflow: hidden;
}

.login_1 {
    width: 40%;
    background-color: #243C5C;
    display: flex;
    justify-content: center;
    align-items: center;

}

.login_img {
    height: 60%;
    width: 60%;
}

.login_2 {
    width: 60%;
 
    background-color: white;

}

.login_header {
    width: 100%;
    height: 18%;
    box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.329);
    -webkit-box-shadow: 0px 3px 7px 0px rgba(124, 124, 124, 0.466);
    -moz-box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
}

.login_header h3
{
    color: var(--body-main-dark-color);
    font-family: "Helvetica Neue", helvetica, arial, sans-serif;

}

.login_content
{
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    padding: 15px;
}

.login_btn
{
    width: 90%;
    background-color: var(--button-color-light);
    box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.329);
    -webkit-box-shadow: 0px 3px 7px 0px rgba(124, 124, 124, 0.466);
    -moz-box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.75);
    border: 1px solid var(--body-main-dark-color);
    padding: 10px;
    border-radius: 5px;
    margin-top: 8px;
    cursor: pointer;
    color: var(--body-main-dark-color);
}

.login_btn span
{
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.login_btn:hover
{
    background-color: var(--body-main-dark-color);
    color: var(--button-color-light);
}

.login_btn span:hover
{
    background-color: var(--body-main-dark-color);
    color: var(--button-color-light);
}


@media (max-width:750px)
{
    .login_card {
        width: 80%;
    }
    .login_1
    {
        display: none;
    }

    .login_2
    {
        width: 100%;
       
    }

    
}


