body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    font-family: Roboto, "Helvetica Neue", sans-serif; 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --body-bg-light: #f0f0f0;
  --body-bg-dark: #243C5C;
  --sidebar-bg-light: rgb(37, 37, 66);
  --sidebar-bg-dark: #555;
  --main-bg-light: #fff;
  --main-bg-dark: #444;
  --button-bg-light: #007bff;
  --button-hover-bg-light: #0056b3;
  --button-bg-dark: #bbb;
  --button-hover-bg-dark: #999;
  --button-color-light: #fff;
  --button-color-dark: #333;
  --table-border-light: #ddd;
  --table-border-dark: red;
  --text-color-dark: #fff;
}

.main
{
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100vh;
}

.header
{
  width: 80%; 
  margin-left: 20%;
}

.popup-content
{
  padding: 0px;
}



/*------ CSS for Side bar ---- */
/* Define styles for success toast */
.toast-success {
  background-color: green;
  color: white;
}

/* Define styles for warning toast */
.toast-warning {
  background-color: orange;
  color: white;
}
