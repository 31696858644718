:root {
    --body-main-dark-color: #243C5C;
    --body-main-light-color: #B3C9E6;
    /* --body-main-light-color: #9dbadfc7; */
    /* --body-main-light-color: #6182aac7; */
    /* --body-main-light-color: #849dbd; */
    /* --sidebar-bg-light-color: #f1f1f1; */
    --sidebar-bg-light-color: #e2e5e7;
    --sidebar-ul-hover-color: #ddd;
    --white: #fff;
    --sidebar-font-color: #333;
    --sidebar-bg-light: rgb(37, 37, 66);
    --sidebar-bg-dark: #555;
    --main-bg-light: #fff;
    --main-bg-dark: #444;
    --button-bg-light: #007bff;
    --button-hover-bg-light: #0056b3;
    --button-bg-dark: #bbb;
    --button-hover-bg-dark: #999;
    --button-color-light: #fff;
    --button-color-dark: #333;
    --table-border-light: #ddd;
    --table-border-dark: red;
    --text-color-dark: #fff;
}

.Employee_header
{
 
    display: flex;
    flex-direction: row;
    width: 100%;
   
}

.Profile_Pic_div
{
    display: flex;
    flex-direction: column;
    width: 18%;
    justify-content: center;
    align-items: center;
}

.Profile_img img
{
    border-radius: 50%;
    height: 100px;

}
.prfoile_upload
{
    display: flex;
    justify-content: center;
}

.Aadhar_img img
{   
    height: 80px;

}
.upload_btn {
    background-color: var(--body-main-dark-color);
    padding-top: 5px;
    padding-bottom: 5px;
    margin-top: 10px;
    height: 30%;
    flex-direction: row;
    display: flex;
    border: 1px solid var(--body-main-dark-color);
    color: var(--button-color-light);
    border-radius: 5px;
    cursor: pointer;
    align-items: center;
    width: 100px;
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, .2), 0 6px 10px 0 rgba(0, 0, 0, .14), 0 1px 18px 0 rgba(0, 0, 0, .12);
  
}
.Profile_dtl
{
    display: grid;
    width: inherit;
    grid-template-columns: repeat(4, 1fr);
}

.profile_grid_c
{
   margin-right: 10px;
   margin-top: 3px;
}
.Emp_slider {
    background-color: #ffffff2b;
    border-radius: 100px;
    padding: 1px;
    margin: 10px;
    margin-top: 20px;
    cursor: pointer;
    transition: box-shadow 0.2s cubic-bezier(0.4, 0, 0.2, 1) 0s;
    align-items: center;
    position: relative;
    display: block;
    width: 41px;
    height: 20px;
    box-shadow: rgba(0, 0, 0, 0.62) 0px 0px 5px inset, rgba(0, 0, 0, 0.21) 0px 0px 0px 24px inset,
          var(--body-main-light-color) 0px 0px 0px 0px inset, rgba(224, 224, 224, 0.45) 0px 1px 0px 0px;
  }
  
  .Emp_slider::after {
    content: "";
    display: flex;
    top: 2.3px;
    left: 2px;
    width: 18px;
    height: 18px;
    background-color: #e3e3e3;
    border-radius: 200px;
    position: absolute;
    box-shadow: transparent 0px 0px 0px 2px, rgba(0, 0, 0, 0.3) 0px 6px 6px;
    transition: left 300ms cubic-bezier(0.4, 0, 0.2, 1) 0s, background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0s;
    will-change: left, background-color;
  }
  
  .Emp_Switch input[type="checkbox"]:checked + .Emp_slider {
    box-shadow: rgba(0, 0, 0, 0.62) 0px 0px 5px inset, var(--body-main-dark-color) 0px 0px 0px 2px inset, var(--body-main-dark-color) 0px 0px 0px 24px inset,
          rgba(224, 224, 224, 0.45) 0px 1px 0px 0px;
  }
  
  .Emp_Switch input[type="checkbox"]:checked + .Emp_slider::after {
    left: 24px;
  }
  
  .Emp_Switch input[type="checkbox"] {
    display: none;
  }

  .Emp_isActive
  {
    display: flex;
    flex-direction: row;
   align-items: center;
  }

  .tabs-container {
    display: flex;
    flex-direction: column;
    width: 100%; /* Adjust width as needed */
    margin-top: 5px;
  }
  
  .tabs {
    display: flex;
    border-bottom: 1px solid #ccc;
    width: 100%;
  }
  
  .tab {
    padding: 8px 16px;
    padding-left: 20px;
    padding-right: 20px;
    cursor: pointer;
    background-color: #f0f0f0;
    /* border: 1px solid var(--body-main-dark-color);
    border-radius: 10px; */
    /* margin-right: 10px; */
    border-right: 1px solid #ccc;
    font-size: 13px;
  }
  
  .tab:last-child {
    border-right: none;
  }
  
.Empactive {
    background-color: var(--body-main-dark-color); 
    color: white;
  }
  
  .tab-content {
    padding: 20px;
  }

  .personal_Info
  {
    height: 100vh;
    max-height: 40vh;
    overflow-y: auto;
  }

  .custom-date-input[type="date"]
  {
    padding-left: 182px;
  }
  .custom-date-input[type="date"]::-webkit-datetime-edit {
    display: none;
    -webkit-appearance: none;
  }
  
  .custom-date-input[type="date"]::-webkit-datetime-edit::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  @media (min-width: 1360px) {
    .Profile_dtl {
        grid-template-columns: repeat(6, 1fr);
    }
}


  @media (max-width:850px) {
    .Profile_dtl
    {
        grid-template-columns: repeat(4, 1fr);
    }
    .mani2 {
        overflow-x: auto;
    }

}

@media (max-width:650px) {
    .Profile_dtl {
        grid-template-columns: repeat(2, 1fr);
    }

   
}

@media (max-width:450px) {
    .Profile_dtl {
        grid-template-columns: repeat(1, 1fr);
        justify-items: center;
    }

    .Employee_header
    {
        flex-direction: column;
        align-items: center;
        
    }
}



