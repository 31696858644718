:root {
    --body-main-dark-color: #243C5C;
    --body-main-light-color: #B3C9E6;
    /* --body-main-light-color: #9dbadfc7; */
    /* --body-main-light-color: #6182aac7; */
    /* --body-main-light-color: #849dbd; */
    /* --sidebar-bg-light-color: #f1f1f1; */
    --sidebar-bg-light-color: #e2e5e7;
    --sidebar-ul-hover-color: #ddd;
    --white: #fff;
    --sidebar-font-color: #333;
    --sidebar-bg-light: rgb(37, 37, 66);
    --sidebar-bg-dark: #555;
    --main-bg-light: #fff;
    --main-bg-dark: #444;
    --button-bg-light: #007bff;
    --button-hover-bg-light: #0056b3;
    --button-bg-dark: #bbb;
    --button-hover-bg-dark: #999;
    --button-color-light: #fff;
    --button-color-dark: #333;
    --table-border-light: #ddd;
    --table-border-dark: red;
    --text-color-dark: #fff;
}

.logout_main
{
    
    width: 100%;
}

.logout_header
{
    background-color: var(--body-main-light-color);
    display: flex;
    align-items: center;
    padding: 10px;
    border-bottom: 2px solid var(--body-main-dark-color);
}

.logout_header span
{
    display: flex;
    align-items: center;
    color: var(--body-main-dark-color);
    font-size: large;
    font-weight: 700;
}

.logout_content
{
    padding: 10px;
}

.logout_sub
{
    color: var(--body-main-dark-color);
    display: flex;
    flex-direction: column;
    text-align: center;
    
}

.logout_sub span
{
    font-size: medium;   
}


.logout_btns
{
    display: flex;
    flex-direction: row;
    justify-content: right;
    /* padding-right: 20px; */
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px solid var(--body-main-light-color);
}


.logbtn1 {
    /* background-color: var(--body-main-dark-color); */
    padding: 5px 15px 5px 15px;
    border: 1px solid var(--body-main-dark-color);
    color: var(--body-main-dark-color);
    border-radius: 5px;
    cursor: pointer;
    align-items: center;
    display: flex;
    align-content: center;
    margin-right: 10px;
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, .2), 0 6px 10px 0 rgba(0, 0, 0, .14), 0 1px 18px 0 rgba(0, 0, 0, .12);

}

.btn2
{
    background-color: var(--body-main-dark-color);
    color: var(--button-color-light);
}
.btn2:hover
{
    background-color: var(--button-color-light);
    color: var(--body-main-dark-color);
}

.logbtn1 span {
    padding-right: 5px;
    align-items: center;

}

.logbtn1:hover {
    background-color: var(--body-main-dark-color);
    color: var(--button-color-light);
}



